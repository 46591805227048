import React from "react";
import { withRouter } from "react-router-dom";

import { Tile, Link } from "carbon-components-react";

import { Office, CloudStrategy, PartnerRelationship } from "@carbon/pictograms-react";

import logo from "../../../images/PBTS_Logo_Text.svg";

class ContactHomePage extends React.Component {

	render() {
		return (
			<div>
				<div className="bx--grid">
					<div className="bx--row contactus-banner-row">
						<div className="bx--col-md-2">
							<img src={logo} alt="log" />
						</div>
						<div className="bx--col-md-6">
							<div>
								<span className="contactus-banner-text-dark">Who We Are...</span>
							</div>
						</div>
					</div>
					<div className="bx--row contactus-service1-row">
						<div className="bx--col-sm-4 contactus-service-title-cell">
							<h2 className="contactus-service-title">Business Application Developers</h2>
							<Office className="contactus-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row contactus-desc-row contactus-service1-row">
						<div className="bx--col-sm-4 bx--col-md-4 contactus-centered-cell">
							<Tile className="contactus-desc">
								<h3>Development</h3>
								<p>A team of experienced web application developers and designers.</p>
								<p>Working closely with your business team, we propose a design that fits your business needs.</p>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-4 contactus-centered-cell">
							<Tile className="contactus-desc">
								<h3>Implementation</h3>
								<p>Through a priority based approach we idenitfy capabilities with most impact and start from there.</p>
								<p>We build and deploy the solution in increments, to make the transition seamless with no disruptions.</p>
							</Tile>
						</div>
					</div>
					<div className="bx--row contactus-service2-row">
						<div className="bx--col-sm-4 contactus-service-title-cell">
							<h2 className="contactus-service-title">Cloud Specialists</h2>
							<CloudStrategy className="contactus-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row contactus-desc-row contactus-service2-row">
						<div className="bx--col-sm-4 bx--col-md-4 contactus-centered-cell">
							<Tile className="contactus-desc">
								<h3>Servers</h3>
								<p>We deploy the applications in the cloud, so you don't have to manage and maintain servers in your business locations.</p>
								<p>We use servers that are provisioned with a cloud provider based on best price and features.</p>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-4 contactus-centered-cell">
							<Tile className="contactus-desc">
								<h3>Maintenance</h3>
								<p>We not only help you with provisioning your environment, but also manage and maintain the servers for you.</p>
								<p>Additionally, we offer maintenance services to keep the applications running optimally with their data backed up reliably. </p>
							</Tile>
						</div>
					</div>
					<div className="bx--row contactus-service3-row">
						<div className="bx--col-sm-4 contactus-service-title-cell">
							<h2 className="contactus-service-title">Contact Information</h2>
							<PartnerRelationship className="contactus-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row contactus-desc-row contactus-service3-row">
						<div className="bx--col-sm-4 bx--col-md-8 contactus-centered-cell">
							<Tile className="contactus-desc">
								<p>
									Reach us by email at:
									<Link className="contactus-email" href="mailto:progressive.biztech@gmail.com"> Progressive BizTech Solutions</Link>
									<Link className="contactus-email" href="mailto:progressive.biztech@gmail.com"> (progressive.biztech@gmail.com)</Link>
								</p>
							</Tile>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(ContactHomePage);