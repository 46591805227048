import React from "react";

class AppFooter extends React.Component {

	render() {
		return (
			<footer className="sgd-footer"></footer>
		);
	}
}

export default AppFooter;