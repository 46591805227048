import React from "react";

import { Tile, UnorderedList, ListItem } from "carbon-components-react";

import RenewTeam from "@carbon/pictograms-react/lib/renew--team"
import Analyze from "@carbon/pictograms-react/lib/analyze"
import Clients from "@carbon/pictograms-react/lib/app--developer"

// import { Link } from "react-router-dom";

import logo from "../../../images/PBTS_Logo_Text.svg";

class HomePage extends React.Component {

	render() {
		return (
			<div>
				<div className="bx--grid">
					<div className="bx--row homepage-banner-row">
						<div className="bx--col-sm-4 bx--col-md-2">
							<img src={logo} alt="log" />
						</div>
						<div className="bx--col-sm-4 bx--col-md-6">
							<div>
								<div>
									<span className="homepage-banner-text-dark">Applications</span>
									<span className="homepage-banner-text-light"> that take</span>
								</div>
								<div className="homepage-banner-text-line2">
									<span className="homepage-banner-text-light">your </span>
									<span className="homepage-banner-text-dark">Business</span>
								</div>
								<div className="homepage-banner-text-line3">
									<span className="homepage-banner-text-light">to the </span>
									<span className="homepage-banner-text-dark">Next Level</span>
								</div>
							</div>
						</div>
					</div>
					<div className="bx--row homepage-service1-row">
						<div className="bx--col-sm-4 bx--col-md-3 homepage-service-title-cell">
							<div className="homepage-service-title">At the Office</div>
							<RenewTeam className="homepage-serivice-pictogram" />
						</div>
						<div className="bx--col-sm-4 bx--col-md-5 homepage-centered-cell">
							<Tile className="homepage-service-desc">
								{/* <p>Empower your staff to perform their daily tasks faster, with higher accuracy using applications that are custom built for your business</p> */}
								<p>Custom built applications for your business empower your staff to perform their daily tasks:</p>
								<UnorderedList className="homepage-ul">
									<ListItem>Independently</ListItem>
									<ListItem>With higher accuracy</ListItem>
									<ListItem>and Faster</ListItem>
								</UnorderedList>
							</Tile>
						</div>
					</div>
					<div className="bx--row homepage-service2-row">
						<div className="bx--col-sm-4 bx--col-md-5 homepage-centered-cell">
							<Tile className="homepage-service-desc">
								{/* <p>Generate custom graphical and tabular reports to better understand the state of your business</p> */}
								<p>Better understand and analyze the state of your business by reviewing:</p>
								<UnorderedList className="homepage-ul">
									<ListItem>Graphical Charts</ListItem>
									<ListItem>Tabular Data</ListItem>
									<ListItem>Dashboards (status alerts)</ListItem>
								</UnorderedList>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-3 homepage-service-title-cell">
							<div className="homepage-service-title">Stay Informed</div>
							<Analyze className="homepage-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row homepage-service3-row">
						<div className="bx--col-sm-4 bx--col-md-3 homepage-service-title-cell">
							<div className="homepage-service-title">Happy Clients</div>
							<Clients className="homepage-serivice-pictogram" />
						</div>
						<div className="bx--col-sm-4 bx--col-md-5 homepage-centered-cell">
							<Tile className="homepage-service-desc">
								{/* <p>Keep your public web site current, to reflect your latest prices, offerings and services. Avoid frustration and missed opportunities</p> */}
								<p>Keep your public web site up-to-date to reflect the latest:</p>
								<UnorderedList className="homepage-ul">
									<ListItem>Prices</ListItem>
									<ListItem>Offerings &amp; Services</ListItem>
									<ListItem>Dashboards (status alerts)</ListItem>
								</UnorderedList>
								<p>Avoid frustration and missed opportunities.</p>
							</Tile>
						</div>
					</div>
				</div>
				{/* <div>
					<div>
						<Link to="/catalog">Catalog Page</Link>
					</div>
					<div>
						<Link to="/report">Report Page</Link>
					</div>
				</div> */}
			</div>
		);
	}
}

export default HomePage;