import React from "react";
import { withRouter } from "react-router-dom";

class AppHeader extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		}
	}

	render() {
		// const currentUser = { firstName: "Joe", lastName: "Ming", role: APP_ROLES.ADMIN };
		return (
			<header className="sgd-header sgd-header-print">
				<div className="sgd-header-left-container">
				</div>
				<div className="sgd-header-right-container">
					<ul className="sgd-hz-list">
						<li
							className="sgd-header-right-item"
							onClick={() => this.props.history.push("/contact")}
						>
							Contact Us
						</li>
						<li
							className="sgd-header-right-item"
							onClick={() => this.props.history.push("/services")}
						>
							Services
						</li>
						<li
							className="sgd-header-right-item"
							onClick={() => this.props.history.push("/")}
						>
							Home
						</li>
					</ul>
				</div>
			</header>
		)
	}
}

export default withRouter(AppHeader);

/* <img id="bridgeCheckLogo" className="cl-header-logo" src={logo} alt="alt-text"></img> */
