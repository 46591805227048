import React from 'react';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import AppHeader from "./components/AppHeader";
import AppFooter from './components/AppFooter';
import HomePage from './content/home/HomePage';
import ContactHomePage from './content/contact/ContactHomePage';
import ServicesHomePage from './content/services/ServicesHomePage';

import './app.scss';

function App() {
	return (
		<Router>
			<AppHeader />
			<div className="sgd-main-content">
				<Switch>
					<Route path="/contact">
						<ContactHomePage />
					</Route>
					<Route path="/services">
						<ServicesHomePage />
					</Route>
					<Route path="/">
						<HomePage />
					</Route>
				</Switch>
			</div>
			<AppFooter />
		</Router>

	);
}

export default App;
