import React from "react";
import { withRouter } from "react-router-dom";

import { Tile } from "carbon-components-react";

import { RenewTeam } from "@carbon/pictograms-react";
import { Analyze } from "@carbon/pictograms-react";
import { AppDeveloper } from "@carbon/pictograms-react"

import logo from "../../../images/PBTS_Logo_Text.svg";

class ServicesHomePage extends React.Component {

	render() {
		return (
			<div>
				<div className="bx--grid">
					<div className="bx--row services-banner-row">
						<div className="bx--col-md-2">
							<img src={logo} alt="log" />
						</div>
						<div className="bx--col-md-6">
							<div>
								<span className="services-banner-text-dark">Solutions &amp; Services</span>
							</div>
						</div>
					</div>
					<div className="bx--row services-service1-row">
						<div className="bx--col-sm-4 services-service-title-cell">
							<h2 className="services-service-title">Custom Application Build</h2>
							<RenewTeam className="services-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row services-desc-row services-service1-row">
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Efficiency & Accuracy</h3>
								<p>Transition manual and paper-based work to applications built for your business.</p>
								<p>Reduce mistakes and streamline your business activities.</p>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Get More done with Less</h3>
								<p>Automate repeated tasks to spend less time and resources on getting work done.</p>
								<p>Complete more tasks and generate more revenue in the same amount of time, with the same resources.</p>
							</Tile>
						</div>
					</div>
					<div className="bx--row services-service2-row">
						<div className="bx--col-sm-4 services-service-title-cell">
							<h2 className="services-service-title">Business Reports</h2>
							<Analyze className="services-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row services-desc-row services-service2-row">
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Review Performance</h3>
								<p>Generate custom reports to better understand how your business is progressing.</p>
								<p>Find areas that contribute the most to your success and scale them up.</p>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Opportunities to Improve</h3>
								<p>Identify factors that prevent you from growing your business, and address them head-on.</p>
								<p>Through visualization of historical data, identify patterns that explain prior successes to build on.</p>
							</Tile>
						</div>
					</div>
					<div className="bx--row services-service3-row">
						<div className="bx--col-sm-4 services-service-title-cell">
							<h2 className="services-service-title">Client Web Sites</h2>
							<AppDeveloper className="services-serivice-pictogram" />
						</div>
					</div>
					<div className="bx--row services-desc-row services-service3-row">
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Dependence & Trust</h3>
								<p>Keep your public web sites up to date with the latest updates in your business.</p>
								<p>Build trust with your clients by offering latest and most accurate business related information in your public web site.</p>
							</Tile>
						</div>
						<div className="bx--col-sm-4 bx--col-md-4 services-centered-cell">
							<Tile className="services-desc">
								<h3>Engagement</h3>
								<p>Go beyond a simple and static public web sites that only offer outdated information.</p>
								<p>Build engaging features to facilitate client interactions by performing self-serve tasks to save their time and reduce your cost.</p>
							</Tile>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(ServicesHomePage);